div[role='dialog'] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal {
  z-index: 999999 !important;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}
.modal-backdrop.fade.in {
  background: rgba(87, 32, 33, 0.4);
  opacity: 0.9;
  backdrop-filter: blur(2px);
  z-index: 100000 !important;
}
.modal.fade.in {
  display: flex;
}
.modal .modal-dialog {
  margin: auto;
}
.modal .modal-content {
  background-color: var(--color-modal-primary);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2) !important;
  border: none;
  border-radius: 16px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal .modal-control {
  position: relative;
  z-index: 999;
  width: 16px;
  height: 60px;
  padding-top: 8px;
  left: calc(100% - 28px);
}
.modal .modal-backdrop {
  display: none !important;
}
.modal .modal-control button.btn-close-modal {
  width: 14px;
  height: 14px;
  color: var(--color-text-primary);
  background-image: url(./btn-close-modal.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  margin-top: 7px;
}
.modal .modal-control.white button.btn-close-modal {
  background-image: none;
}
.modal .modal-control.white button.btn-close-modal::after {
  content: '\f00d';
  font-family: 'FontAwesome';
  color: var(--color-text-secondary);
  font-size: 1.25em;
}
.modal .modal-body {
  padding: 25px 35px 35px 35px;
  border-radius: 16px;
  background-color: transparent;
}
.modal .modal-content {
  border-radius: 16px;
}
.modal .modal-body .content-top {
  width: 70%;
  margin: -55px auto 0 auto;
}
.modal .modal-body .content-top h5 {
  margin-bottom: 6px;
}

.modal.modal-small .modal-content {
  width: 282px;
  border-radius: 16px;
  margin: auto;
  box-shadow: none !important;
}
.modal.modal-small .modal-content h3 {
  font-weight: 600;
  margin-bottom: 10px;
}
.modal.modal-small .modal-content p {
  margin-top: 20px;
}
.modal.modal-small .modal-content p span {
  font-weight: 600;
}
.modal.modal-small .modal-content p {
  margin-bottom: 18px;
}
.modal.modal-small .modal-content p.icon {
  color: #e5aa44;
  font-size: 3.4em;
  margin-bottom: 6px !important;
}
.modal.modal-small .modal-content p.icon span {
  font-weight: 400 !important;
}
.modal.modal-small .modal-content .btn-primary {
  margin-top: 15px;
}

.modal-dialog-transparent .modal-content {
  background-color: transparent;
  box-shadow: none !important;
}

/*--- Mobile Landscape and Portrait ---*/
@media (max-width: 767px) {
  .modal {
    display: table;
    height: 100%;
    width: 100%;
    margin: auto;
  }
  .modal .modal-dialog {
    width: 85%;
    vertical-align: middle;
    pointer-events: none;
  }
  .modal .modal-content {
    /* To center horizontally */
    margin: 20px auto;
    pointer-events: all;
    border-radius: 16px;
    top: 0;
  }
  .modal.modal-small .modal-body {
    padding: 25px 35px 35px 35px;
  }
  .modal.modal-small .modal-content {
    width: 260px;
    max-width: 80vh;
  }
}
/* Mobile Portrait Only*/
@media (max-width: 479px) {
  .modal .modal-dialog {
    max-width: 320px;
  }
  .modal .modal-body {
    padding: 35px 24px 24px;
  }
  .modal .modal-body .content-top {
    width: 80%;
    margin-top: -75px;
  }
  .modal-sm-70vh {
    width: 70vh !important;
  }
}
/*max-height: 85vh;*/
