.UpsellScreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  background-color: var(--color-modal-cart);
}

.UpsellContent {
  flex: 1;
  padding: 8px 0 24px;
  overflow: auto;
}

.UpsellCategoryRow {
  flex-direction: column;
  margin-bottom: 24px;
}

.UpsellScreen .UpsellCategoryName {
  text-align: center;
  padding: 8px 24px;
  text-transform: uppercase;
}

.UpsellSection {
  padding: 0 24px;
  overflow-x: auto;
  text-align: center;
}

.UpsellMenusRow {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Accomodate maximum of 6 menu items */
  max-width: 1250px;
}

.UpsellScreen .ActionButton .Button.dim {
  background-color: var(--color-contrast);
  color: var(--color-text-contrast);
}

.UpsellScreen .UpsellHeart {
  scale: 1.5;
  margin-bottom: 1px;
}

.UpsellScreen .UpsellTitle {
  text-align: center;
  margin-top: 16px;
}

.UpsellScreen .UpsellMobileList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 16px;
}

.MenuItem.isUpselling .UpsellMenuButton {
  overflow: hidden;
  flex-direction: row;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
}

.MenuItem.isUpselling.isUpsellSelected .UpsellMenuButton {
  background-color: var(--color-secondary);
}

.MenuItem.isUpselling .UpsellMenuButton span {
  font-weight: bold;
  margin-right: 16px;
}

/* For tablet only */
@media (max-width: 992px) and (min-width: 768px) {
  .UpsellContent {
    padding-bottom: 54px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .UpsellScreen {
    background-color: var(--color-modal-cart);
  }

  .UpsellContent {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 2px 3px none;
    overflow-y: auto;
  }

  .UpsellScreen .UpsellTitle {
    text-align: left;
    margin-left: 32px;
  }

  .UpsellCategoryName,
  .UpsellSection {
    text-align: left;
  }

  .UpsellMenusRow {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    max-width: none;
  }

  .UpsellScreen .UpsellTitle {
    text-align: left;
    margin-left: 24px;
  }
}
