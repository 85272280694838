.MenuItem.isUpselling .menu-desc {
  padding: 8px;
}

.MenuItem.isUpselling .menu-desc .h5 {
  margin: 0;
}

.MenuItem.isUpsellSelected {
  border: none;
}

.MenuItem .UpsellStatus {
  display: None;
}

.MenuItem.isUpsellSelected .UpsellStatus {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  color: white;
}

.UpsellStatus .lnr {
  font-size: 2.4em;
  margin-bottom: 4px;
}

.MenuItem.isUpsellSelected .UpsellStatus::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary);
  opacity: 0.5;
}

.MenuItem.isUpsellSelected .menu-image {
  background-color: var(--color-secondary);
}

.MenuItem.isUpselling {
  background-color: white;
  padding-bottom: 0;
  min-height: 192px;
}

.MenuItem.isUpselling .menu-image > img {
  max-height: 96px;
  object-fit: cover;
}
