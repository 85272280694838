.CategoryPaginationBar {
  text-align: center;
  margin: 14px 0;
  padding: 0%;
}

.CategoryPaginationBar > li {
  display: inline-block;
}

.CategoryPaginationBar > li:first-child {
  margin-right: 2%;
}

.CategoryPaginationBar > li:first-child > ul > li {
  display: inline-block;
  font-size: 1.2em;
  margin-right: 20px;
  opacity: 1;
  color: var(--color-text-primary);
}

.CategoryPaginationBar > li:first-child > ul > li > .Button {
  text-transform: uppercase;
}

.CategoryPaginationBar > li:first-child > ul > li:last-child {
  margin-right: 0;
}

.CategoryPaginationBar > li:first-child li.selected {
  opacity: 1 !important;
  background-color: initial !important;
  border: initial !important;
  border-bottom: none !important;
  color: var(--color-light);
}

.CategoryPaginationBar > li:nth-child(2) input {
  width: 100%;
}

.CategoryPaginationBar > li:nth-child(3) {
  padding-left: 2%;
}

/* Mobile Portrait and Landscape */
@media (max-width: 767px) {
  .CategoryPaginationBar {
    background-color: none;
    height: auto;
    margin: 0;
    box-shadow: none;
  }

  .CategoryPaginationBar > li:first-child {
    width: auto;
  }

  .CategoryPaginationBar > li:first-child > ul {
    width: auto;
    display: flex;
  }

  .CategoryPaginationBar > li:first-child > ul > li {
    opacity: 1;
    height: auto;
    line-height: auto;
    flex: 1;
  }

  .CategoryPaginationBar > li:first-child li > button {
    font-size: 0.95em;
    line-height: 20px;
  }
}

/* Mobile Portrait Only */
@media (max-width: 479px) {
  .CategoryPaginationBar > li:first-child > ul > li {
    margin-right: 10px;
  }
}
