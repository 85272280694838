.ScheduleTimePicker {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.ScheduleTimePicker .Content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 30px;
  width: 80%;
  margin: 0 auto;
}

.ScheduleTimePickerOption {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 10px 12px;
  border: solid 1px var(--color-primary);
  background-color: var(--color-background);
  color: var(--color-text-primary);
  border-radius: 60px;
  text-align: center;
  font-weight: normal;
  height: 46px;
  cursor: pointer;
}

.ScheduleTimePickerOption .indicator {
  height: 18px;
  width: 18px;
  border: solid 1px var(--color-background);
  color: var(--color-background);
  border-radius: 50%;
  display: block;
  margin-left: 4px;
  margin-right: 10px;
  float: left;
  position: relative;
  top: 2.5px;
}

.ScheduleTimePickerOption .indicator .fa {
  font-size: 0.7em;
  position: absolute;
  left: 2.6px;
  top: 2.6px;
}

.ScheduleTimePickerOption .content {
  position: absolute;
  left: 0;
  right: 0;
}

.ScheduleTimePickerOption .content h6 {
  font-weight: bold;
}

.ScheduleTimePickerOption.active {
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
}

.ScheduleTimePickerOption.active .indicator {
  background-color: var(--color-background);
  border-color: var(--color-secondary);
  color: var(--color-text-primary);
}

.ScheduleTimePickerOption input {
  display: none;
}

.OptionSeparator {
  margin: 14px 0;
  display: block;
}

.ScheduleTimePicker .Header {
  margin: 16px 0 16px;
  font-size: 1em;
  font-weight: 600;
}

.ScheduleTimePickerDate {
  display: flex;
  width: 100%;
  margin: 16px auto 0;
}

.ScheduleTimePickerDate .SelectWrapper {
  flex: 1;
  height: 50px;
}

.ScheduleTimePickerDate .Select {
  border-radius: 60px;
  padding-left: 16px;
}

.ScheduleTimePickerDate .dropdown #pickup-date {
  width: 100%;
}

.ScheduleTimePickerDate .TimePicker {
  display: flex;
}

.ScheduleTimePickerDate .TimePicker .SelectWrapper::after {
  display: initial;
}

.ScheduleTimePickerDate .TimePicker select {
  appearance: none;
  padding: 0 32px 0 20px;
  line-height: 46px;
  width: fit-content;
  border-color: var(--color-primary);
  color: var(--color-text-primary);
  margin-left: 8px;
  border-radius: 60px;
}

.ScheduleTimePicker .FreshnessMessage {
  margin-top: 24px;
  margin: 24px auto;
  max-width: 250px;
  line-height: 1.3;
}

.ScheduleTimePicker .FreshnessMessage {
  margin-top: 24px;
  margin: 24px auto;
  max-width: 250px;
  line-height: 1.3;
}

.ScheduleTimePicker .TimeSlotLabel {
  margin-top: 24px;
}

.ScheduleTimePicker .TimeSlotLabel + .Header {
  margin-bottom: 0px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 992px) {
  .ScheduleTimePicker .Content {
    padding: 0 32px 32px;
    width: 100%;
  }
  .ScheduleTimePickerOption,
  .ScheduleTimePickerDate {
    max-width: auto;
  }

  .ScheduleTimePicker .ScheduleTimePickerDate .dropdown-menu {
    top: initial;
    bottom: 100%;
  }

  .ScheduleTimePickerDate.isDelivery {
    flex-direction: column;
  }

  .ScheduleTimePickerDate .TimePicker.TimeRangePicker select {
    margin-left: 0;
    margin-top: 8px;
  }

  .ErrorText {
    color: var(--color-text-error);
    margin-top: 24px;
  }
}
