.OrderStatus {
  margin-top: 0px;
  text-align: center;
  background-color: var(--color-secondary);
  border-radius: 16px;
  margin: 0 32px;
  padding: 16px 24px;
}
.OrderStatus ul {
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: flex;
}

.OrderStatus ul li {
  display: inline-block;
  color: #ec812d;
  font-size: 1.13em;
  vertical-align: middle;
}
.OrderStatus ul li img.iconic {
  width: 16px;
  margin-right: 6px;
  margin-top: -2px;
}
.OrderStatus ul li:first-child {
  flex: 1;
  text-align: left;
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.OrderStatus.OrderStatusAccepted ul li:first-child {
  top: initial;
}
.OrderStatus ul li.timer {
  text-align: right;
  font-size: 1em;
  height: 40px;
}
.OrderStatus ul li.timer img {
  height: 40px;
}
.OrderStatus span.bold {
  font-size: 1.1em;
}
.OrderStatus span.time-actual {
  font-size: 1em;
  line-height: 1.3em;
}

.OrderStatus span > p {
  display: inline;
}

.OrderStatus .ContactHelp {
  margin-top: 12px;
}

.OrderStatus .ContactHelp > p {
  margin: 0;
}

.OrderStatus > h6 + h6 {
  margin: 6px 0;
}

.OrderStatus a {
  font-weight: 600;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .OrderStatus ul {
    max-width: 360px;
  }
  .OrderStatus {
    margin: 0 24px;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .OrderStatus {
    margin: 24px;
  }
  .OrderStatus ul {
    max-width: 440px;
  }
  .OrderStatus ul li {
    font-size: 1em;
  }
  .OrderStatus ul li img.iconic {
    margin-right: 4px;
    position: relative;
    top: -1px;
  }
  .OrderStatus ul li.timer {
    font-size: 1em;
  }
}
