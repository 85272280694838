.GglocationsSelectWrapper {
  margin: 5px auto 0px;
}
.GglocationsSelect select {
  width: 100%;
}
.StoreIcon {
  width: 17px;
  height: auto;
  margin-right: 3px;
}

.GglocationsSelect.MuiOutlinedInput-root {
  height: 48px;
  border-radius: 60px;
  background-color: var(--color-background);
}

.GglocationsSelect .MuiSelect-select {
  font-size: 0.85em;
  font-weight: 600;
  color: var(--color-text-primary);
}

.GglocationsSelect .GglocationsSelectInputLabel svg {
  margin-right: 8px;
  margin-bottom: -2px;
}

.GglocationsSelect .MuiOutlinedInput-notchedOutline {
  font-family: 'Work Sans', sans-serif;
  border-radius: 60px;
  border: 1px solid var(--color-primary);
}

.GglocationsSelect:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--color-primary) !important;
}

.GglocationsSelectWrapper .GglocationsSelect.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.GglocationsSelect .MuiSelect-icon {
  right: 16px;
  color: var(--color-text-primary);
}

.GglocationsSelectBasePopover {
  z-index: 30000000 !important;
}

.GglocationsSelectPopover .GglocationsSelectMenuItem {
  display: flex;
  justify-content: space-between;
  font-size: 0.95em;
}

.GglocationsSelectMenuItem .GglocationsSelectExtraInfo {
  color: #807e7a;
}
