.PersonalizeOrderItem {
  padding-bottom: 16px;
}

.PersonalizeForm {
  width: 100%;
}
.PersonalizeForm p {
  font-size: 1em;
  color: var(--color-light);
  margin-bottom: 4px;
  padding-left: 8px;
  font-weight: 400;
}
.PersonalizeForm ul {
  display: flex;
  flex-wrap: wrap;
  max-width: 560px;
}
.PersonalizeForm ul li {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
  margin: 16px 0;
}
.PersonalizeForm .dropdown-toggle {
  width: 100%;
}
.PersonalizeForm ul.dropdown-menu li {
  width: 100%;
}
.PersonalizeForm ul.dropdown-menu {
  z-index: 1000000;
}
.PersonalizeForm ul li.left {
  margin-right: 1%;
}
.PersonalizeForm input {
  width: 100%;
  max-width: 500px;
}
.PersonalizeForm .dropdown {
  width: 100%;
}

.dropdown button,
.OrderInfo {
  padding: 12px 16px !important;
  background-color: transparent;
  border: 1px solid var(--color-primary);
  box-shadow: none;
  color: var(--color-text-primary);
  text-align: left;
}
.OrderInfo {
  color: var(--color-text-grey);
}
.OrderInfo:focus,
.dropdown button:focus {
  outline: none;
}
.OrderInfo span.caret,
.dropdown button span.caret {
  color: var(--color-secondary);
  position: absolute;
  right: 20px;
  top: 20px;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
.OrderInfo::-webkit-input-placeholder {
  color: #f6c096;
  text-align: left;
}
.OrderInfo::-moz-placeholder {
  color: #f6c096;
  text-align: left;
}

.OrderInfo:-ms-input-placeholder {
  color: #f6c096;
  text-align: left;
}

.PersonalizeForm ul li:nth-child(odd) {
  margin-right: 24px;
}

.PersonalizeBase-2 .PersonalizeChopping {
  width: 100%;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .PersonalizeForm {
    max-width: 600px;
    margin: auto;
  }
  .PersonalizeForm ul {
    position: relative;
    max-width: 600px;
  }
  .PersonalizeForm ul li.PersonalizeAdditions {
    margin-right: 0;
  }
  .PersonalizeForm ul li.PersonalizeChopping {
    position: absolute;
    right: 0;
  }
  .PersonalizeForm input {
    width: 100%;
    max-width: 100%;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) and (min-width: 480px) {
  .PersonalizeForm {
    max-width: 600px;
    margin: auto;
  }
  .PersonalizeForm ul {
    position: relative;
    max-width: 600px;
  }
  .PersonalizeForm ul li.left {
    margin-right: 0;
  }
  .PersonalizeForm ul li.right {
    position: absolute;
    right: 0;
  }
  .PersonalizeForm input {
    width: 100%;
    max-width: 100%;
  }
}

/* Mobile Landscape Only */
@media (max-width: 767px) {
  .PersonalizeForm ul {
    flex-direction: column;
  }

  .PersonalizeForm ul li {
    max-width: unset;
  }

  .PersonalizeForm ul li:nth-child(odd) {
    margin-right: 0;
  }
}
