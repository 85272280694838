a.Button,
a.Button:hover {
  color: inherit;
}

.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.Button > span {
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.Button.disabled {
  opacity: 1;
}

.Button.loading {
  position: relative;
}

.Button.loading .fa-spin {
  position: absolute;
  top: 20px;
  left: 20px;
}

.Button.btn-teal {
  color: var(--color-text-primary);
  background-color: var(--color-content);
  border: none;
  border-radius: 60px;
  padding: 12px 60px;
  font-weight: 600;
}
.Button.btn-teal:hover,
.Button.btn-teal:focus,
.Button.btn-teal:active {
  border-width: 0px;
  background-color: var(--color-secondary);
}
.Button.btn-primary {
  color: var(--color-text-contrast);
  background-color: var(--color-primary);
  padding: 12px 60px;
  font-size: 1em;
  border-radius: 60px;
  outline: 0 !important;
}
.Button.btn-primary:hover,
.Button.btn-primary:focus,
.Button.btn-primary:active,
.Button.btn-primary:active:hover {
  color: var(--color-text-contrast);
  background-color: var(--color-contrast);
  outline: 0 !important;
}
.Button.disabled.btn-primary,
.Button.disabled.btn-primary:hover {
  background-color: #c2bfbb;
  color: #e6e3e0;
}
.Button.btn-secondary {
  border: 1px solid var(--color-primary);
  padding: 12px 60px;
  font-size: 1em;
  color: var(--color-text-primary);
  border-radius: 60px;
}
.Button.btn-secondary:hover,
.Button.btn-secondary:focus {
  background-color: transparent;
  color: var(--color-text-primary);
  border-width: 2px;
}
.Button.disabled.btn-secondary,
.Button.disabled.btn-secondary:hover {
  background: #e6e3e0;
  color: #acaaa8;
  border: 1px solid #acaaa8;
}
.Button.btn-round {
  background-color: var(--color-primary);
  border-radius: 60px;
  color: var(--color-text-contrast);
  padding: 8px 12px;
  font-size: 0.9em;
}
.Button.btn-round:hover,
.Button.btn-round:active,
.Button.btn-round:focus {
  color: var(--color-text-contrast);
  background-color: var(--color-contrast);
}
.Button a {
  color: inherit;
}
.Button a:focus {
  color: inherit;
}
.Button a:hover {
  color: inherit;
}

.Button.btn-grey {
  background-color: #8ca1a5;
  padding: 12px 60px;
  font-size: 1.4em;
  border-radius: 60px;
  outline: 0 !important;
  color: var(--color-text-contrast);
}
.Button.btn-grey:hover,
.Button.btn-grey:focus,
.Button.btn-grey:active {
  background-color: #6e8286 !important;
  outline: 0 !important;
}
.Button.btn-grey a {
  color: var(--color-text-contrast) !important;
}

.Button.tabsButton {
  background-color: transparent;
  color: var(--color-text-primary);
}

.Button.tabsButton:hover,
.Button.tabsButton:active {
  background-color: var(--color-secondary);
}

.Button.Split > span {
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.Button .Text {
  display: flex;
  justify-content: center;
  flex: 1;
}

.Button.left .Text {
  padding-right: 40px;
}

.Button.right .Text {
  padding-left: 40px;
}

.Button .Text > span {
  margin: auto 0;
}

.Button .Next {
  margin-right: 16px;
  height: 19px;
  margin-top: 2px;
}

.Button.RightEndArrowButton {
  position: relative;
}

.Button.RightEndArrowButton::after {
  position: absolute;
  right: 16px;
  content: url('../../images/arrow-right.svg');
}
