.FeedbackSticker {
  background-color: transparent;
  border-radius: 16px;
  color: var(--color-text-grey);
  padding: 16px 16px;
  margin-bottom: 16px;
}

.FeedbackSticker p {
  margin-bottom: 0;
}

.FeedbackSticker p:nth-child(n + 2) {
  margin-top: 5px;
}

.FeedbackSticker div {
  display: flex;
  align-items: center;
}

.FeedbackSticker .lnr {
  font-size: 1.5em;
  margin-right: 8px;
  position: relative;
  top: 4px;
}

.FeedbackSticker .fa {
  font-size: 1.2em;
  margin-right: 8px;
}

.FeedbackSticker.filled {
  background-color: var(--color-content);
}
