.OrderItem {
  margin-bottom: 8px;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid var(--color-secondary);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;
}

.PickFavorite .OrderItem,
.NameFavorite .OrderItem {
  background-color: white;
}

.OrderItem .OrderItemTitle {
  display: flex;
  justify-content: space-between;
}

.OrderItem .OrderItemTitle > :first-child {
  font-weight: bold;
}

.OrderItem ul {
  display: flex;
  width: 100%;
}

.OrderItem > ul > li:first-child {
  width: 23%;
  height: auto;
  position: relative;
  display: flex;
}

.OrderItem > ul > li:first-child button {
  background-color: #ffffff;
  width: 100%;
  padding: 8px;
  color: #95989a;
  border-top: 1px solid #f2ede9;
  font-size: 0.9em;
  position: absolute;
  bottom: 0;
}

.OrderItem > ul > li:first-child .order-img {
  width: 100%;
  vertical-align: middle;
  min-height: 110px;
  overflow: hidden;
  position: relative;
  display: flex;
}

.OrderItem .order-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 0px;
}

.OrderItem > ul > li:nth-child(2) {
  width: 77%;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.OrderItem > ul > li:nth-child(2) h5 {
  font-size: 1.1em;
  margin-bottom: 6px;
}

.OrderItem .OrderItemIngredients {
  padding-bottom: 8px;
  padding-right: 108px;
}

.OrderItem > ul > li:nth-child(2) ul {
  font-size: 0.9em;
  z-index: 10000;
  padding: 0;
  position: relative;
  display: flex;
}

.OrderItem > ul > li:nth-child(2) ul li {
  display: flex;
  align-items: center;
}

.OrderItem > ul > li:nth-child(2) ul li:nth-child(1) {
  padding-right: 16px;
}

.OrderItem > ul > li:nth-child(2) ul li.Label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}

.OrderItem > ul > li:nth-child(2) ul li.Label > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.OrderItem > ul > li:nth-child(2) ul li:last-child {
  margin-left: auto;
}

.OrderItem .isFavorite .lnr-heart {
  color: #ff0000;
}

.OrderItem p span {
  display: inline;
}

.OrderItem .OrderItemPrice {
  color: var(--color-text-grey);
}

.OrderItem.Small {
  text-align: left;
}

.OrderItem.Small > ul > li:first-child {
  width: 68px;
  display: flex;
  align-items: center;
}

.OrderItem.Small > ul > li:nth-child(2) {
  width: calc(100% - 68px);
}

.OrderItem.Small > ul > li:nth-child(2) h5 {
  font-size: 1em;
  line-height: 1.6em;
  margin-bottom: 1px;
  margin-top: 0;
}

.OrderItem.Small p {
  font-size: 0.8em;
  line-height: 1.4em;
  margin-bottom: 3px;
}

.OrderItem.Small > ul > li:nth-child(2) ul {
  display: none;
}

.OrderItem.Small .FavoriteLink {
  display: none;
}

.OrderItem.Small > ul > li:nth-child(3) {
  width: 45px;
}

.OrderItem.Small > ul > li:nth-child(3) .Button {
  width: 100%;
  height: 100%;
}

.OrderItem.Small .OrderItemPrice {
  display: none;
}

/*--- Tablet Only ---*/
@media (min-width: 768px) and (max-width: 992px) {
  .OrderItem > ul > li:first-child {
    width: 30%;
  }

  .OrderItem > ul > li:nth-child(2) {
    width: 70%;
  }
}

/*--- Mobile Landscape and Portrait --*/
@media (max-width: 767px) {
  .OrderItem > ul > li:first-child {
    width: 33%;
  }

  .OrderItem > ul > li:first-child button {
    padding: 12px;
  }

  .OrderItem > ul > li:nth-child(2) {
    width: 67%;
  }

  .OrderItem .OrderItemIngredients {
    padding-right: 8px;
  }
}
