.HiddenClimatePositive {
  display: flex;
  flex-direction: column;
  margin: 12px 0 0;
}

.HiddenClimatePositive .DbsLogoWrapper {
  height: 48px;
}

.HiddenClimatePositive .DbsLogoWrapper .DbsLogo {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.CarbonOffsetWrapper {
  display: flex;
  flex-direction: column;
}

.CarbonOffsetWrapper .DbsLogoWrapper {
  height: 48px;
  margin-top: -12px;
  position: relative;
}

.CarbonOffsetWrapper .DbsLogoWrapper .DbsLogo {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.CarbonOffsetBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-content);
  border-radius: 16px;
  padding: 16px;
  margin: 24px 0;
}

.CarbonOffsetBody .bi-info-circle {
  cursor: pointer;
}

.CarbonOffsetBody .bi-info-circle {
  cursor: pointer;
}

.CarbonOffsetBody .CarbonOffsetLabel {
  flex: 1 1 auto;
  text-align: left;
}

.CarbonOffsetBody .CarbonOffsetLabel button {
  width: 24px;
  min-width: auto;
  color: var(--color-text-primary);
}

.CarbonOffsetBody .CarbonOffsetPrice {
  margin-right: 10px;
}

.CarbonOffsetBody .CarbonOffsetToggle {
  display: flex;
  align-items: center;
}

.CarbonOffsetBody .CarbonOffsetToggle label {
  margin-bottom: 0;
}

.CarbonOffsetHeader {
  display: none;
  justify-content: left;
}

.CarbonOffsetHeader svg {
  margin-left: 10px;
}

.CarbonOffsetDescription {
  padding: 8px 24px 24px 24px;
  text-align: justify;
}

.CarbonOffsetTitle {
  padding: 16px 0 0px;
}
.CarbonOffsetModal .modal-control {
  position: absolute;
}

.CarbonOffsetModal .CarbonOffsetInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 24px 0;
}

.CarbonOffsetButtonPrice {
  margin-left: 8px;
}

.CarbonOffsetModal img {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.CarbonOffsetModal .modal-body button {
  height: 50px;
  padding: 8px 32px;
  border-radius: 60px;
}

/*--- Desktop only ---*/
@media (min-width: 768px) {
  .CarbonOffsetModal.modal .modal-control button.btn-close-modal {
    background-image: url(../../images/icon-close.svg);
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .CarbonOffsetModal .CarbonOffsetInfo {
    padding-top: 16px;
  }
  .CarbonOffsetModal .CarbonOffsetInfo > img {
    display: none;
  }

  .CarbonOffsetTitle {
    padding: 16px 0 0px;
  }
}

@media (max-width: 573px) {
  .CarbonOffsetModal .CarbonOffsetInfo .Button {
    font-size: 14px;
    padding: 10px 20px;
  }
}
