.Autocomplete {
  position: relative;
}

.AutocompleteInput {
  background-color: white;
}

.AutocompleteSuggestions {
  position: absolute;
  top: 100%;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 8px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  padding: 4px;
  width: 100%;
}

.AutocompleteSuggestions:empty {
  box-shadow: none;
  padding: 0;
}

.AutocompleteSuggestion {
  border-bottom: none;
}

.AutocompleteSuggestionButton {
  text-align: left;
  width: 100%;
  padding: 10px;
}

.AutocompleteSuggestionButton:hover {
  background-color: #eeeeee;
}

.Autocomplete .InputAlertText {
  margin-top: 4px;
}
