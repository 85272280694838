.TakeOutWithBagOption {
  display: flex;
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 16px;
  overflow: visible;
  cursor: pointer;
  position: relative;
  transition: 0.5s ease-out;
}

.TakeOutWithBagOption .TakeOutWithBagOptionBody {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 8px;
  border: 1px solid var(--color-secondary);
  border-left: none;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: var(--color-panel-secondary);
  align-items: center;
  text-align: center;
}

.TakeOutWithBagOption.isTakeOutWithBagEnabled .TakeOutWithBagOptionBody {
  background-color: var(--color-content);
  color: var(--color-text-grey);
  border: 2px solid var(--color-secondary);
  border-left: none;
}

.TakeOutWithBagOption .TakeOutWithBagImage {
  width: 96px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: var(--color-panel-secondary);
  border: 1px solid var(--color-secondary);
  border-right: none;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.TakeOutWithBagOption.isTakeOutWithBagEnabled .TakeOutWithBagImage {
  background-color: var(--color-content);
  border: 2px solid var(--color-secondary);
  border-right: none;
}

.TakeOutWithBagOption .TakeOutWithBagOptionName {
  flex: 1;
  text-align: left;
}

.TakeOutWithBagOption .TakeOutWithBagPrice {
  min-width: 108px;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
}

.TakeOutWithBagOption .TakeOutWithBagOptionAdd {
  color: #ec842d;
  font-weight: 600;
}

.TakeOutWithBagOption .TakeOutWithBagOptionCancelButton {
  width: 64px;
}

.TakeOutWithBagOption.isTakeOutWithBagEnabled .TakeOutWithBagOptionCancelButton {
  font-size: 1.2em;
  color: var(--color-text-primary);
  position: relative;
  transition: 0.5s ease-out;
}

.TakeOutWithBagOption.isTakeOutWithBagEnabled .TakeOutWithBagOptionCancelButton:hover {
  color: var(--color-contrast);
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .TakeOutWithBagOption .TakeOutWithBagPrice {
    min-width: 50px;
  }

  .TakeOutWithBagOption:focus.isTakeOutWithBagEnabled {
    transform: translateX(-70px);
  }

  .TakeOutWithBagOption .TakeOutWithBagOptionCancelButton {
    width: 0;
  }

  .TakeOutWithBagOption.isTakeOutWithBagEnabled .TakeOutWithBagOptionCancelButton {
    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transform: translateX(70px);
  }
}
