.LoyaltyCollection {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.LoyaltyCollection .tabsRow {
  display: flex;
  width: 640px;
}

.LoyaltyCollection .tabsRow .Button {
  font-size: 2.6em;
  flex: 0 1 auto;
  background-color: none;
  color: var(--color-primary);
  border-radius: 16px 16px 0 0;
  clip-path: none;
  border: none;
  text-transform: uppercase;
  padding-left: 0;
}

.LoyaltyCollection .tabsRow .Button + .Button {
  margin-left: 90px;
}

.LoyaltyCollection .tabsRow .Button.selected,
.LoyaltyCollection .tabsRow .Button.selected:focus {
  background-color: transparent;
  margin-top: 0px;
  border: none !important;
  color: var(--color-light);
}

.LoyaltyCollection .tabsRow .Button.tabsButton:hover,
.LoyaltyCollection .tabsRow .Button.tabsButton:active {
  background-color: transparent;
  color: var(--color-light);
}

.LoyaltyCollection .SectionDescription {
  margin: 12px;
  text-align: center;
}

.LoyaltyCollection .SectionDescription .MuiTypography-root + .MuiTypography-root {
  margin-top: 16px;
}

.LoyaltyCollection .SectionDescription .btn-primary {
  margin-top: 24px;
}

.LoyaltyCollection .PromoSection {
  padding-top: 16px;
}

.LoyaltyCollection .SectionTitle {
  text-align: center;
}

.LoyaltyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.LoyaltyCollection .blue-link {
  margin: 8px auto;
}

/* Tablet and Mobile */
@media (max-width: 991px) {
  .LoyaltyCollection {
    padding: 32px 8px;
    overflow: initial;
  }
}

/* Tablet Portrait and Mobile */
@media (max-width: 767px) {
  .LoyaltyCollection {
    padding: 20px 0px 8px;
    overflow: initial;
  }

  .LoyaltyCollection .tabsRow {
    width: 75%;
  }

  .LoyaltyCollection .tabsRow .Button {
    font-size: 1.6em;
  }

  .LoyaltyCollection .tabsRow .Button + .Button {
    margin-left: 20px;
  }

  .LoyaltyCollection .LoyaltyCards {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .LoyaltyCollection .SectionTitle {
    margin-bottom: 8px;
  }

  .LoyaltyCollection .blue-link {
    display: none;
  }
}
