.FavoriteMenuItem {
  display: flex;
  background-color: var(--color-content);
  border: none;
  box-shadow: none;
  margin: 0 8px 8px 0;
  text-align: left;
  min-height: 100px;
  min-width: 480px;
  border-radius: 16px;
  overflow: hidden;
}

.FavoriteMenuItem .Image {
  width: 100px;
  display: inline-flex;
  align-items: center;
}

.FavoriteMenuItem .Image img {
  width: 100%;
  object-fit: contain;
}

.FavoriteMenuItem .Details {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 8px 12px;
  justify-content: center;
}

.FavoriteMenuItem .Details h5 {
  margin-bottom: 8px;
}

.FavoriteMenuItem .Button.EditName {
  font-size: 0.85em;
  margin-left: 5px;
  color: var(--color-text-primary);
  height: auto;
  display: none;
}

.FavoriteMenuItem .Details p {
  line-height: 1.4em;
}

.FavoriteMenuItem .Details p + p {
  margin-top: 8px;
}

.FavoriteMenuItem .Details .AddedIngredientsText {
  font-style: normal;
}
.FavoriteMenuItem .Details .RemovedIngredientsText {
  font-style: normal;
}

.FavoriteMenuItem .Toolbar {
  padding: 24px 16px;
}

.FavoriteMenuItem .Toolbar .Button {
  width: 100%;
  height: 100%;
  font-size: 1.25em;
  color: var(--color-text-primary);
  align-items: flex-start;
}

.FavoriteMenuItem.NoButtons .EditName,
.FavoriteMenuItem.NoButtons .Toolbar {
  display: none;
}

/*--- Tablet & Mobile ---*/
@media (max-width: 992px) {
  .FavoriteMenuItemsList {
    flex: 1;
  }

  .FavoriteMenuItem {
    min-width: unset;
  }
}
