.Counter {
  background-color: var(--color-primary);
  display: flex;
  justify-content: space-around;
  padding: 8px 16px;
  color: var(--color-text-contrast);
}

.Counter .Value {
  font-weight: 600;
}
