.Spinner {
  margin: auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.Spinner.center {
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
.Spinner .Spinner-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.Spinner .Spinner-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.Spinner .Spinner2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.Spinner .Spinner3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.Spinner .Spinner4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.Spinner .Spinner5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.Spinner .Spinner6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.Spinner .Spinner7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.Spinner .Spinner8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.Spinner .Spinner9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.Spinner .Spinner10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.Spinner .Spinner11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.Spinner .Spinner12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.Spinner .Spinner2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.Spinner .Spinner3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.Spinner .Spinner4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.Spinner .Spinner5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.Spinner .Spinner6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.Spinner .Spinner7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.Spinner .Spinner8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.Spinner .Spinner9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.Spinner .Spinner10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.Spinner .Spinner11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.Spinner .Spinner12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.Spinner.logoSpinner .SaladAnimation {
  width: 80px;
  height: auto;
  margin-top: 0px;
  top: -50%;
  left: -50%;
  position: absolute;
}

.Spinner.center.logoSpinner .SaladAnimation {
  top: -50%;
}
