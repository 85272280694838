.UserButton {
  margin-left: 24px;
}

.UserButton.LandingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 35px;
  border-radius: 20px;
}

.UserButton .SignInButton {
  color: var(--color-secondary);
}

.UserButton .SignInButton > span {
  flex: 1 0 auto;
}

.UserButton .AccountButton img {
  height: 40px;
}
