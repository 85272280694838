.Select {
  background: var(--color-background);
  appearance: none;
  padding: 0 32px 0 20px;
  line-height: 46px;
  height: 100%;
  width: 100%;
  border-color: var(--color-primary);
  border-width: 1px;
  color: var(--color-text-primary);
  border-radius: 60px;
  font-size: 1em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.SelectWrapper {
  position: relative;
  height: 100%;
  display: flex;
}

.SelectWrapper::after {
  position: absolute;
  top: 15px;
  right: 14px;
  content: '\F0D7';
  font-family: 'FontAwesome';
  font-size: 16px;
  color: var(--color-primary);
  pointer-events: none;
}

.Select.gray {
  border-color: #e2dfd8;
}

.SelectWrapper .icon {
  position: absolute;
  justify-self: center;
  align-self: center;
  left: 18px;
  top: 15px;
}

.Select {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select option {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Select:focus,
.Select:focus-visible {
  outline: none;
}
