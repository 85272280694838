.LoadingScreen {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.LoadingScreen h6 {
  margin-top: 20px;
}

.LoadingScreen .SaladAnimation {
  width: 80px;
  height: auto;
}
