.GglocationInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.GglocationInfo .AddressSticker {
  display: flex;
  flex: 1 1 auto;
  border-radius: 16px;
  background: transparent;
  border: none;
  padding: 0px;
  font-size: 14px;
  overflow: hidden;
}

.GglocationInfo .AddressNameLine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GglocationInfo .DeliveryAddressNameLine {
  border-radius: 60px;
  border: 1px solid var(--color-primary);
  background-color: var(--color-background);
  color: var(--color-text-grey);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 12px 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  height: 46px;
  flex: 1;
}

.GglocationInfo .AddressNameLine .Distance {
  font-size: 1.125em;
}

.GglocationInfo .AddressTitle {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  text-transform: uppercase;
}

.GglocationInfo .gglocationAddress {
  margin-bottom: 8px;
}

.GglocationInfo .StoreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.GglocationInfo .StoreInfo a {
  width: auto;
  margin-left: auto;
}

.GglocationInfo .LastOrderTime {
  font-style: normal;
  margin-top: 8px;
}

.GglocationInfo .AddressDetails {
  flex: 1;
}

.GglocationInfo.fromOrder .AddressDetails {
  text-align: left;
}

.GglocationInfo.fromOrder .AddressNameLine,
.GglocationInfo.fromOrder .StoreInfo {
  justify-content: flex-start;
  flex-direction: row;
}

.GglocationInfo.fromOrder .LastOrderTime {
  margin-top: 0px;
}

.GglocationInfo .blue-link {
  font-size: 12px;
  font-weight: 600;
}

.GglocationInfo .OrderAddressTitle {
  display: inline-flex;
  font-weight: 600;
  font-size: 1.2em;
}

.GglocationInfo .OrderAddressTitle img {
  height: 24px;
  margin-right: 8px;
  align-self: center;
}

.GglocationInfo .OrderTimeDetails {
  text-align: right;
  margin-top: 5px;
  max-width: 40%;
}

.GglocationInfo .StationLabel {
  text-transform: uppercase;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 992px) {
  .GglocationInfo .StationLabel {
    display: none;
  }
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .GglocationInfo .AddressNameLine .AddressTitle {
    width: 85%;
    margin: 0 auto;
    justify-content: center;
  }
}
