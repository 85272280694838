.LoyaltyCard {
  margin: 8px 8px 24px;
  display: flex;
  flex-direction: column;
  width: 640px;
  border-radius: 16px;
  background-color: var(--color-panel-secondary);
  box-shadow: 2px 4px 8px rgba(54, 53, 52, 0.1);
  cursor: pointer;
  overflow: hidden;
}

.LoyaltyCard .LoyaltyCardBody {
  display: flex;
  flex-direction: row;
}

.LoyaltyCard .LoyaltyCardContentContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  overflow: hidden;
}

.LoyaltyCard .LoyaltyCardImageContainer {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
}

.LoyaltyCard .LoyaltyCardImageContainer img {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.LoyaltyCard .TitleContainer {
  flex: 1;
  width: 100%;
  align-self: flex-start;
  border-top-right-radius: 16px;
  overflow: hidden;
  text-align: center;
}

.LoyaltyCard .Title {
  padding: 12px 24px;
  color: var(--color-text-contrast);
  background-color: var(--color-primary);
}

.LoyaltyCard.ClaimCard .Title {
  background-color: var(--color-light);
}

.LoyaltyCard .TitleLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.LoyaltyCard .NameContainer {
  text-align: left;
  padding: 16px 24px 8px;
}

.LoyaltyCard .CardBottom {
  padding: 0px 24px 24px;
}

.LoyaltyCard .ValidityRow {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.LoyaltyCard .Validity,
.LoyaltyCard .Remaining {
  display: inline;
}

.LoyaltyCard .Remaining {
  font-style: italic;
  margin-left: auto;
}

.LoyaltyCard .ProgressBarBackground {
  height: 24px;
  border-radius: 24px;
  margin: 18px 0 16px;
}

.LoyaltyCard .ProgressBar {
  height: 24px;
  border-radius: 24px;
}

.LoyaltyCard .BonusSticker {
  margin-bottom: 8px;
}

.LoyaltyCard.ClaimCard .LoyaltyCardBody {
  width: 100%;
}

.LoyaltyCard.ClaimCard .ClaimDescription {
  margin-top: 8px;
}

@media (max-width: 767px) {
  .LoyaltyCard {
    flex-direction: column;
    width: unset;
    height: unset;
    margin: 8px 16px;
  }

  .LoyaltyCard .TitleContainer {
    border-radius: 0;
  }

  .LoyaltyCard .LoyaltyCardImageContainer {
    width: 100%;
    height: 96px;
    display: none;
  }

  .LoyaltyCard .LoyaltyCardImageContainer img {
    object-fit: cover;
    flex: 1;
    position: relative;
    height: 96px;
  }
}
