.OrderStatusModal .modal-body {
  padding-top: 40px !important;
  padding-bottom: 50px !important;
}

.OrderStatusModal .SaladAnimation {
  margin-top: 24px;
  width: 80px;
  height: auto;
}

.OrderStatusModal.modal.modal-small .modal-content {
  width: 300px;
}
