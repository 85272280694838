.LoyaltyFab {
  position: fixed;
  bottom: 100px;
  right: calc(33.3% - 40px - 80px);
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 80px;
  background-color: #00564a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 9000;
}

.LoyaltyFab:focus {
  background-color: #00564a;
}

.LoyaltyFab.isFabOpen,
.LoyaltyFab:hover {
  background: linear-gradient(0deg, #000 0%, #000 100%), #00564a;
}

.LoyaltyFab svg {
  width: 48px;
  height: 48px;
}

.LoyaltyFab .LoyaltyFabCount {
  background-color: var(--color-light);
  color: var(--color-text-contrast);
  position: absolute;
  top: -6px;
  right: -12px;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoyaltyFab svg path {
  fill: var(--color-modal-primary);
}

.LoyaltyFab.isFabOpen svg path,
.LoyaltyFab:hover svg path {
  fill: var(--color-modal-primary);
}

.LoyaltyFab.isFabOpen .LoyaltyFabCount,
.LoyaltyFab:hover .LoyaltyFabCount {
  background-color: var(--color-light);
  color: var(--color-text-contrast);
}

.LoyaltyFabPopover {
  z-index: 99999999 !important;
}

.LoyaltyFabPopoverPaper {
  padding-right: 16px;
  width: 400px;
}

.LoyaltyFabPopoverPaper .LoyaltyRewardsList {
  max-height: 480px;
  overflow: auto;
}

.LoyaltyFabPopoverPaper .LoyaltyReward,
.LoyaltyFabPopoverPaper .SelectedReward {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1));
  background-color: var(--color-content);
}

.LoyaltyFabPopoverPaper .LoyaltyFabRewardButton {
  background-color: var(--color-content);
}

.LoyaltyFabPopoverPaper .LoyaltyFabRewardButton,
.LoyaltyFabPopoverPaper .LoyaltyFabRewardButton:hover {
  margin: 4px 0 16px;
  width: 100%;
  height: 48px;
}

.LoyaltyFabPopoverPaper .LoyaltyFabRewardButton:hover {
  color: var(--color-text-primary);
}

.Menu .LoyaltyFab {
  left: 48px;
  bottom: 80px;
}

/*--- Desktop Only ---*/
@media (min-width: 992px) {
  .LoyaltyFabPopoverPaper .LoyaltyRewardsList {
    padding: 0;
  }
}

/*--- Tablet and Mobile ---*/
@media (max-width: 991px) {
  .LoyaltyFab {
    display: none;
  }

  .LoyaltyFabPopoverPaper .LoyaltyRewardsList .LoyaltyRewardWrapper {
    padding: 0;
  }
}
