.GuestInfoInputWrapper {
  margin-top: 4px;
}

.GuestInfoInputMain {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.GuestInfoInputMain .GuestEmailInput {
  position: relative;
}

.GuestInfoInputMain .GuestEmailInput .Input,
.GuestInfoInputMain .GuestPhone .Input {
  width: 100%;
  background-color: transparent;
  font-size: 18px;
  padding: 12px 20px !important;
  height: 48px;
}

/* Layering buttons and input */
.GuestInfoInputMain .GuestEmailInput .Input,
.GuestInfoInputMain .GuestPhone .Input {
  z-index: 1;
}

.GuestInfoInputMain .GuestEmailInput .Input.hasError {
  background-color: #fbece9 !important;
}

.GuestInfoInputMain .GuestEmailInput .ClearButton {
  z-index: 2;
}

.GuestInfoInputMain .GuestEmailInput .Input::placeholder {
  font-size: 14px;
  font-family: 'Work Sans';
}

.GuestInfoInputMain .GuestEmailInput .ClearButton {
  position: absolute;
  right: 16px;
  top: 15px;
  color: var(--color-text-primary);
}

.GuestInfoInputMain.hasError .GuestEmailInput .ClearButton {
  color: var(--color-text-error);
}

.GuestInfoInputMain > .Button {
  padding: 0 12px;
  height: 48px;
}

.GuestInfoInputWrapper .GuestEmailError {
  margin-top: 4px;
  color: var(--color-text-error);
  text-align: left;
  padding-left: 8px;
  font-size: 14px;
}

.GuestInfoInputWrapper .GuestEmailText {
  margin-top: 4px;
  text-align: left;
  padding-left: 8px;
  font-size: 14px;
  color: var(--color-text-primary);
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .GuestInfoInputMain {
    margin-top: 8px;
  }

  .GuestInfoInputMain .GuestEmailInput,
  .GuestInfoInputMain .GuestPhone {
    margin-right: 0;
  }

  .GuestInfoInputMain .GuestEmailInput,
  .GuestInfoInputMain .GuestPhone {
    width: 100%;
  }

  .GuestInfoInputMain > .Button.btn-primary {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
