.NutritionalBlock {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  padding: 8px 32px 16px;
}

.NutritionalBlock .NutritionalDisclaimer {
  margin-top: 12px;
}

.NutritionalBlock #NutritionalTabs {
  padding: 10px 0 16px;
  border-bottom: 0;
}

.NutritionalBlock #NutritionalTabs li {
  margin: 0 10px;
  flex: initial;
}

.NutritionalBlock #NutritionalTabs li:first-child {
  margin-left: 0;
}

.NutritionalBlock #NutritionalTabs li:last-child {
  margin-right: 0;
}

.NutritionalBlock #NutritionalTabs li a {
  padding: 0;
  margin: 0;
}

.NutritionalBlock .NutrientsList {
  width: 100%;
}

/* Mobile Portrait and Landscape */
@media (max-width: 992px) {
  .NutritionalBlock {
    background-color: var(--color-secondary);
    width: 100%;
    z-index: 1000;
    font-weight: 600;
    height: 40px;
    padding: 8px 16px;
  }

  .NutritionalBlock .NutrientName,
  .NutritionalBlock .NutrientUnit,
  .NutritionalBlock .NutrientValue h6 {
    color: var(--color-text-primary);
  }

  .NutritionalBlock .NutrientValue {
    font-weight: 600;
    padding-left: 5px;
  }

  .NutritionalBlock #NutritionalTabs,
  .NutritionalBlock .NutritionalDisclaimer,
  .NutritionalBlock .NutrientsList > ul {
    display: none;
  }

  .NutritionalBlock .NutrientsList > ul li {
    flex: auto;
  }

  .NutritionalBlock .NutrientsList > ul.visibleOnMobile {
    display: flex;
  }

  .CyoDetails.kale {
    display: none;
  }

  .NutritionalBlockDisplayNone {
    display: none;
  }
}

/* Mobile Portrait */
@media (max-width: 479px) {
  .NutritionalBlock {
    padding: 6px 0;
  }

  .NutritionalBlock .NutrientsList,
  .NutritionalBlock .NutrientsList ul.visibleOnMobile {
    display: flex;
    justify-content: space-evenly;
    column-count: 1;
    font-size: 0.95em;
  }

  .content-main.kale {
    padding-top: 32px;
  }
}

/* Mobile Landscape and Tablet */
@media (min-width: 480px) and (max-width: 992px) {
  .NutritionalBlock .NutrientsList {
    display: flex;
    justify-content: space-between;
    column-count: 1;
  }

  .NutritionalBlock .NutrientsList.ViewNutritionalInfoRow {
    justify-content: center;
  }

  .content-main.kale {
    padding-top: 32px;
  }
}
