.ProgressBar {
  height: 5px;
  background-color: #00564a;
}
.ProgressBarBackground {
  height: 5px;
  width: 100%;
  background-color: #e6e3e0;
  overflow: visible;
  position: relative;
}

.ProgressBarBackground .ProgressBarStep {
  position: absolute;
  top: -24px;
}
