.LoyaltyTab {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-secondary);
  position: relative;
}

.LoyaltyTabs.tab .LoyaltyTab {
  flex: 1;
  flex-direction: column;
  color: var(--color-text-secondary);
  padding-top: 8px;
  font-size: 0.9em;
}

.LoyaltyTab .TabImageWrapper {
  position: relative;
  z-index: 1;
}

.LoyaltyTab .TabImageWrapper img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.LoyaltyTabs .LoyaltyTab {
  font-weight: normal;
}

.LoyaltyTabs .LoyaltyTab.isSelected {
  color: var(--color-extra-light);
  font-weight: 600;
}

.LoyaltyTabs.tab .LoyaltyTab.isSelected .TabImageWrapper::before {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  background-color: var(--color-light);
  content: '';
  top: -28px;
  left: -28px;
  position: absolute;
  z-index: -1;
}

.LoyaltyTabs.tab .LoyaltyTab.isSelected .TabImageWrapper img {
  position: absolute;
  top: -18px;
  left: -16px;
  width: 32px;
  height: 32px;
}

/*--- For desktop ---*/
@media (min-width: 992px) {
  .LoyaltyTabs.bar .LoyaltyTab {
    font-size: 1.2em;
    padding: 0 16px;
  }

  .LoyaltyTabs.bar .LoyaltyTab.order {
    order: -1;
  }

  .LoyaltyTabs.bar .LoyaltyTab .TabImageWrapper {
    padding-right: 10px;
    margin-bottom: 0px;
  }

  .LoyaltyTabs.bar .LoyaltyTab .TabImageWrapper img {
    width: 40px;
    height: 40px;
  }

  .LoyaltyTabs.bar .LoyaltyTab.isSelected:not(.isDisabled) {
    border-bottom: 5px solid var(--color-primary);
    padding-top: 5px;
  }

  .LoyaltyTabs.bar .LoyaltyTab.isDisabled {
    opacity: 0.5;
  }
}
