.AddAddress {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  background-color: var(--color-secondary);
}
.AddAddress .ContentContainer {
  display: flex;
  flex-direction: column;
  width: 37vw;
}
.AddAddress .FormContainer {
  flex: 1;
}
.AddAddress .FormContainer .Button {
  position: static;
}
.AddAddress .ContentContainer .FormContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.AddAddress .FormContainer .FormContent {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px 20px 32px;
}
.AddAddress .FormContainer .FormContent > ul {
  padding: 10px;
  width: 100%;
}
.AddAddress .FormContainer .FormContent > ul > li > .Label {
  font-weight: 400;
  margin: 0 10px;
}
.AddAddress .FormContainer .FormContent > ul > .InputAlertText {
  text-align: left;
  color: var(--color-text-error);
  margin: 10px 0 0;
}
.AddAddress .FormContainer .FormContent > ul > .InputAlertText p {
  margin: 0;
}
.AddAddress .FormContainer .FormContent > ul > li {
  max-width: 360px;
  margin: 0 auto;
}
.AddAddress .FormContainer .FormContent > ul > li input.hasError {
  border-color: var(--color-text-error);
}
.AddAddress .FormContainer .FormContent > ul > li input {
  margin-top: 0px;
  border-radius: 60px;
  border-color: var(--color-primary);
  border-width: 1px;
  width: 100%;
  height: 50px;
}
.AddAddress .geocoder-control-input {
  margin-top: 2px;
  border-radius: 2px;
  border-color: var(--color-primary);
  border-width: 1px;
  width: 92%;
  height: 50px;
  margin-left: 20px;
}
.AddAddress .FormContainer .FormContent .user-top {
  display: block;
  vertical-align: bottom;
  height: 40vh;
  padding-top: calc((50vh - 260px) / 2);
}
.AddAddress .im-home {
  height: 68vh;
  min-height: 415px;
}
.AddAddress .img-home img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  min-height: 415px;
}
.AddAddress .FormContainer .input-alert-text {
  text-align: center;
  color: var(--color-text-error);
}

/* Tablet and Mobile */
@media (max-width: 992px) {
  .App.AddAddressPage {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
  .App.AddAddressPage .TopBar {
    position: unset;
  }
  .AddAddress {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
  .AddAddress .ContentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    width: 100vw;
    height: auto;
    min-height: auto;
    position: relative;
  }
  .AddAddress .ContentContainer .FormContainer .ActionButton {
    position: initial;
  }
  .AddAddress .FormContainer {
    overflow-y: hidden;
  }
  .AddAddress .FormContainer .FormContent {
    padding-top: 32px;
  }
  .AddAddress .FormContainer .FormContent > ul {
    padding-top: 16px;
  }
  .AddAddress.isInputFocused {
    position: relative;
  }
  .AddAddress.isInputFocused .ContentContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1000;
  }
  .AddAddress.isInputFocused .ContentContainer .FormContainer {
    flex: 1;
  }
}
