.Ingredient {
  width: 160px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--color-secondary);
  box-shadow: none;
  background: linear-gradient(180deg, white 0%, #fffdf8 100%);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  touch-action: manipulation;
  min-height: 150px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
}
.Ingredient.removeable:hover {
  cursor: pointer;
}
.Ingredient.replaceable:hover {
  cursor: pointer;
}
.Ingredient.removeable:focus {
  border: 1px solid var(--color-secondary);
}
.Ingredient.replaceable:focus {
  border: 1px solid var(--color-secondary);
}
.Ingredient.disabled::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.Ingredient.resolved::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.Ingredient.selected {
  background-color: #f5f2ed !important;
  border: initial !important;
}
.Ingredient.selected::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #92d27f;
  border-radius: 16px;
}
.Ingredient .IngredientIncludeLabel,
.Ingredient .IngredientIncludePrice {
  text-align: center;
  margin-top: auto;
  font-size: 12px;
  color: #807e7a;
  padding-top: 2px;
}
.Ingredient .IngredientIncludePrice {
  color: var(--color-text-primary);
}
.Ingredient .IngredientIncludePrice .Price {
  display: inline-flex;
}

.Ingredient.removeable:focus::after {
  content: url(icon-remove.svg);
  position: absolute;
  top: 20%;
  left: 26%;
  width: 50%;
  height: auto;
  text-align: center;
  display: none;
}
.Ingredient.replaceable:focus::after {
  content: url(icon-change.svg);
  position: absolute;
  top: 20%;
  left: 26%;
  width: 50%;
  height: auto;
  text-align: center;
  display: none;
}
.Ingredient.disabled {
  border: none;
}
.Ingredient.disabled::after {
  content: '';
  position: absolute;
  font-size: 0.95em;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--color-text-primary);
  background-color: #807e7a;
  opacity: 0.4;
}
.Ingredient.resolved::after {
  content: '';
  position: absolute;
  font-size: 0.95em;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--color-text-primary);
  backdrop-filter: blur(1px);
}
.Ingredient.resolved {
  border: none;
}
.Ingredient.selected::after {
  content: url(icon-added.svg);
  position: absolute;
  top: 45%;
  transform: translateY(-70%);
  left: 28%;
  width: 50%;
  height: auto;
}

.Ingredient:focus {
  outline: none;
}
.Ingredient.removeable:focus .IngredientImage::after {
  content: '';
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(213, 81, 87, 0.65);
  display: none;
}
.Ingredient.replaceable:focus .IngredientImage::after {
  content: '';
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-secondary);
  opacity: 0.4;
  display: none;
}
.Ingredient.selected .IngredientImage:after {
  content: '';
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(47, 121, 58, 0.4);
}
.Ingredient.removeable .IngredientRemove {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  background: none;
  z-index: 1000;
}
.Ingredient.replaceable .IngredientReplace {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  background: none;
  z-index: 1000;
}
.Ingredient.removeable:focus .IngredientRemove,
.Ingredient.Label.removeable .IngredientRemove,
.Ingredient.replaceable:focus .IngredientReplace,
.Ingredient.Label.replaceable .IngredientReplace {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: initial;
  height: initial;
  background-image: initial;
  padding: 0;
}
.Ingredient.removeable:focus .IngredientRemove > span,
.Ingredient.Label.removeable .IngredientRemove > span,
.Ingredient.replaceable:focus .IngredientReplace > span,
.Ingredient.Label.replaceable .IngredientReplace > span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: initial;
  height: initial;
  padding: 0;
  z-index: 1001;
}

.IngredientImage {
  width: 100%;
  display: block;
}
.IngredientImage img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.IngredientName {
  font-size: 0.85em;
  text-align: center;
  padding: 8px 5px 0 5px;
  display: block;
  position: relative;
  height: auto;
}
.IngredientName p {
  position: relative;
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
}

.disabled .IngredientImage img,
.disabled .IngredientName,
.resolved .IngredientImage img,
.resolved .IngredientName {
  opacity: 0.8;
  position: relative;
}

.Ingredient .Servings {
  color: var(--color-text-contrast);
  background-color: #7d4a23;
  border-radius: 20px;
  min-width: 20px;
  height: 20px;
  padding: 2px 6px;
  display: inline-block;
  margin-right: 3px;
  font-size: 0.9em;
  line-height: 16px;
}

.Ingredient.Label {
  display: inline;
  background-image: none;
  background-color: var(--color-secondary);
  border: none;
  padding: 10px 30px 10px 10px;
  border-radius: 8px;
  margin: 3px 8px 3px 0;
  position: relative;
  width: auto;
  max-width: initial;
  min-height: auto;
}

.Ingredient.Label .IngredientImage {
  display: none;
}

.Ingredient.Label .IngredientName {
  font-size: 1em;
  padding: 0;
}

.Ingredient.Label .IngredientName p {
  font-size: 0.9em;
  white-space: nowrap;
  display: inline;
  margin: 0;
}

.Ingredient.Label.removeable::before {
  border: 0;
}

.Ingredient.Label.removeable::after {
  content: '';
  background: url(icon-remove-small.svg) no-repeat;
  top: calc(50% - 4.5px);
  width: 10px;
  height: 16px;
  position: absolute;
  right: 12px;
  left: initial;
  cursor: pointer;
}

.Ingredient.Label.replaceable::after {
  content: '';
  background: url(icon-swap-small.svg);
  top: calc(50% - 4.5px);
  width: 9px;
  height: 8px;
  position: absolute;
  right: 12px;
  left: initial;
  cursor: pointer;
}

.Ingredient.Label .Servings {
  background-color: var(--color-primary);
}

.Ingredient .IngredientResolveLabel {
  display: none;
}

.Ingredient.resolved .IngredientResolveLabel {
  display: flex;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 36px;
  font-weight: 700;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .Ingredient {
    width: 160px;
    margin: 0 12px 12px 0;
    min-height: 170px;
  }
  .Ingredient.removeable:focus::after {
    top: 18%;
    left: 26%;
  }
  .Ingredient.replaceable:focus::after {
    top: 18%;
    left: 26%;
  }
  .Ingredient.Label .IngredientName p {
    font-weight: 400;
  }
  .Ingredient.Label .IngredientIncludePrice {
    display: none;
  }
}

/*--- Mobile Portrait ---*/
@media (max-width: 479px) {
  .Ingredient {
    width: 30%;
    min-height: 150px;
  }
  .Ingredient:nth-child(3n) {
    margin-right: 0;
  }
}
