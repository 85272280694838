.ToastWrapper {
  display: none;
  position: fixed;
  top: 176px;
  right: 0;
  z-index: 10000;
  text-align: center;
  width: calc(100% - 46vw);
  opacity: 0;
}

.ToastWrapper.slideInUp {
  display: block;
  opacity: 1;
}

.Toast {
  background-color: #fde4de;
  padding: 12px 16px;
  color: var(--color-text-grey);
  text-align: center;
  font-size: 1em;
  border-radius: 16px;
  border: none;
  box-shadow: none;
  display: inline-block;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .ToastWrapper {
    left: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ToastWrapper {
    top: 140px;
  }
  .Toast {
    width: 90vw;
  }
}
