.LoyaltyTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.LoyaltyTabs.tab {
  padding: 0px 4px 8px;
  background-color: var(--color-text-hyperlink);
  box-shadow: 0px 3px 8px 3px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.LoyaltyTabs.bar {
  height: 100%;
}

/*--- Desktop and Tablet Landscape ---*/
@media (min-width: 992px) {
  .LoyaltyTabs.tab {
    display: none;
  }
}

/*--- Smaller Desktop ---*/
@media (max-width: 1270px) {
  .LoyaltyTabs.bar .LoyaltyTab > div:nth-child(2) {
    display: none;
  }
}

/*--- Tablet and Mobile ---*/
@media (max-width: 991px) {
  .LoyaltyTabs.bar {
    display: none !important;
  }
}
