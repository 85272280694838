.PersonalizePreferenceGroupSwitch {
  display: grid;
}

.PersonalizePreferenceGroupSwitch label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 46px;
  padding: 8px 24px;
}

.PersonalizePreferenceGroupSwitch .btn-default {
  border-color: var(--color-primary);
}

.recommended-text {
  display: inline-block;
  font-size: 10px;
}

@media (max-width: 330px) {
  .recommended-text {
    font-size: 8px;
  }
}
