.CartItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 16px;
  overflow: visible;
  cursor: pointer;
}

.CartItem .CartItemUpperBody {
  display: flex;
  flex-wrap: wrap;
  min-height: 108px;
  text-align: left;
  position: relative;
  background-color: transparent;
  border: none;
  box-shadow: none;
  overflow: visible;
  cursor: pointer;
}

.CartItem .CartItemBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  border: 1px solid var(--color-secondary);
  border-left: none;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: var(--color-panel-secondary);
}

.CartItem .CartItemBody .CartItemLine {
  display: flex;
  align-items: center;
  text-align: center;
}

.CartItem .CartItemBody .CartItemLine .CartItemName {
  flex: 1;
  text-align: left;
}

.CartItem .CartItemBody .CartItemLine .CartItemPrice {
  min-width: 108px;
  justify-content: flex-end;
  padding-right: 16px;
}

.CartItem .CartItemBody .CartItemDetails {
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
}

.CartItem .CartItemBody .Customer {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 16px;
}

.CartItem .CartItemBody .Customer::before {
  content: 'Label:';
  font-size: 12px;
  padding-right: 4px;
  font-weight: 600;
}

.CartItem .CartItemBody .CartItemPrice {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.CartItem .CartItemBody .CartItemPrice .OrderItemPrice h6 {
  line-height: 1;
}

.CartItem .CartItemBody .CartItemPrice .OrderItemPrice.isDiscounted .OriginalPrice {
  padding-top: 0;
  margin-bottom: 0;
  align-self: flex-end;
}

.CartItem .CartItemBody .CartItemPrice .OrderItemPrice {
  justify-content: flex-end;
}

.CartItem .CartItemBody .CartItemDetails p {
  margin-bottom: 0;
}

.CartItem .CartItemBody .CartItemChanges {
  margin-right: 160px;
}

.CartItem li {
  overflow: hidden;
  transition: 0.5s ease-out;
}

.CartItem:focus {
  outline: 0;
}

.CartItem .ingredientChanges {
  white-space: pre-wrap;
}

.CartItem .priceLabel {
  font-size: 0.85em;
  color: #7d7769;
  padding-right: 4px;
}

.CartItem img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.CartItem .CartItemImage {
  width: 96px;
  padding: 6px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--color-panel-secondary);
  border: 1px solid var(--color-secondary);
  border-right: none;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.CartItem .CartItemPrice {
  color: var(--color-text-grey);
  font-weight: 600;
}

.CartItem .EditIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.75em;
  color: var(--color-primary);
}

.CartItem .CartItemAction {
  display: flex;
  padding: 24px;
  flex-direction: column;
  color: var(--color-primary);
  font-size: 1.2em;
  justify-content: center;
}

.CartItem .CartItemAction button + button {
  margin-top: 16px;
}

.CartItem .CartItemAction button:hover {
  color: #d47428;
}

.CartItem .CartItemCounter {
  display: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  border: 2px solid var(--color-primary);
  border-top: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  transition: 0.5s ease-out;
}

.CartItem .CartItemCounter .Button {
  flex: 1;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .CartItem:focus .CartItemUpperBody > li,
  .CartItem:focus .CartItemCounter {
    transform: translateX(-70px);
  }

  .CartItem:focus .CartItemUpperBody .CartItemAction {
    transform: translateX(0);
  }

  .CartItem .CartItemAction {
    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transform: translateX(70px);
  }

  .CartItem .CartItemBody .CartItemQuantitySelector {
    display: none;
  }

  .CartItem .CartItemBody .CartItemChanges {
    margin-right: 8px;
  }

  .CartItem .CartItemCounter {
    display: flex;
  }

  .CartItem .CartItemBody,
  .CartItem .CartItemImage {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: white;
  }
}
