.EditAccountForm .InputLabel {
  font-weight: bold;
  margin-left: 8px;
}

.EditAccountForm .EditAccountFormRow,
.EditAccountForm .EditAccountFormLongRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 160px;
}

.EditAccountForm .EditAccountFormLongRow {
  padding-right: 0;
  flex-wrap: nowrap;
}

.EditAccountForm .EditAccountFormColumn,
.EditAccountForm .EditAccountFormShortColumn {
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  padding-right: 24px;
  margin-bottom: 16px;
  max-width: 50%;
}

.EditAccountForm .EditAccountFormShortColumn {
  flex: 1;
  width: 160px;
  padding-top: 28px;
}

.Account .EditAccountForm .InputInfo,
.Account .EditAccountForm .InputAlertText {
  margin: 4px 0 0;
}

.EditAccountForm .EditAccountFormButtonRow {
  margin: 8px 0 32px;
}

.EditAccountForm .EditAccountFormButtonRow .Button {
  width: 150px;
}

.EditAccountForm .EditAccountFormButtonRow .Button + .Button {
  margin-left: 16px;
}

.EditAccountForm input:focus,
.EditAccountForm input.changed {
  background-color: white;
  border-width: 2px;
}

.Account .FeedbackSticker {
  width: calc(100% - 24px);
  padding: 12px 16px;
}

.EditAccountForm .MainEmail {
  background-color: var(--color-content);
}

.EditAccountForm .MainEmail:focus {
  background-color: var(--color-content);
  border-width: 1px;
}

.EditAccountForm .MarketingEmailRadio {
  display: flex;
  height: 52px;
  align-items: center;
}

.EditAccountForm .MarketingEmailRadio > label {
  margin-bottom: 0;
  font-weight: 400;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.EditAccountForm .MarketingEmailRadio input[type='radio'] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  background-clip: content-box;
  padding: 3px;
  margin: 0 8px 0 0;
}

.EditAccountForm .MarketingEmailRadio input[type='radio']:checked {
  background-color: var(--color-primary);
}

.EditAccountForm .MarketingEmailRadio label + label {
  margin-left: 24px;
}

ul.phone-no {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.phone-no > li:first-child {
  flex: 0 0 36%;
  min-width: 70px;
  margin-right: 4px;
  margin-bottom: 4px;
}

ul.phone-no > li:nth-child(2) {
  flex: 1;
  min-width: 140px;
}

/*--- Mobile Landscape & Portrait ---*/
@media (max-width: 767px) {
  .EditAccountForm .EditAccountFormRow,
  .EditAccountForm .EditAccountFormLongRow {
    flex-direction: column;
    padding-right: 0;
  }

  .EditAccountForm .EditAccountFormColumn {
    flex: 1;
    padding-right: 0px;
  }

  .EditAccountForm .EditAccountFormShortColumn {
    padding: 0 0 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .Account .FeedbackSticker {
    width: 100%;
  }

  .EditAccountForm .EditAccountFormButtonRow {
    margin: 4px auto 32px;
    text-align: center;
  }

  .EditAccountForm .EditAccountFormColumn {
    max-width: unset;
  }
}
