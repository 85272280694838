.PickupTime {
  width: 100% !important;
}
.PickupTime .SelectWrapper #pickup-type {
  width: 100% !important;
  background-color: white;
  color: var(--color-text-primary);
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  text-align-last: center;
}

.PickupTime .SelectWrapper.whiteCaret::after {
  color: var(--color-text-primary);
}

.PickupTime .scheduled {
  display: flex;
  flex: 2 2 !important;
  max-width: unset !important;
  justify-content: space-between;
}

.PickupTime .SelectWrapper.fullWidth {
  flex: 1;
  margin-right: 10px;
}
.PickupTime #pickup-date.Select,
.PickupTime .TimePicker .Select {
  padding: 0 32px 0 20px;
  height: auto;
}

.PickupTime .Select {
  margin-bottom: 0px;
}

.PickupTime .ant-time-picker {
  width: 100%;
}

.ant-time-picker-panel-select-option-disabled {
  display: none;
}

.PickupTime span.instant-order {
  content: url(../../../images/icon-order-instant.svg) !important;
  width: 14px;
  height: 14px;
  margin-right: 16px;
  position: relative;
  top: 3px;
}
.PickupTime span.scheduled-order {
  content: url(../../../images/icon-order-schedule.svg) !important;
  width: 14px;
  height: 14px;
  margin-right: 16px;
  position: relative;
  top: 3px;
}

.PickupTime .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-order-schedule.svg);
  position: absolute;
  color: var(--color-text-contrast);
  top: 15px;
  left: 13px;
}

.PickupTime.isInstant .SelectWrapper.whiteCaret::before {
  content: url(../../../images/icon-order-instant.svg);
}
