.TopBar {
  width: 100%;
  height: 72px;
  background-color: var(--color-primary);
  text-align: center;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  flex: 0 0 auto;
  display: flex;
  padding: 0 28px;
  color: var(--color-text-secondary);
}

.TopBar .TopBarTitle {
  flex: 3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TopBar .TopBarTitle h4,
.TopBar .TopBarTitle p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TopBar .TopBarTitle p svg,
.TopBar .TopBarTitle p img {
  vertical-align: middle;
  margin: 0 4px 4px 0;
}

.TopBar .TopBarLeftButton,
.TopBar .TopBarRightButton {
  flex: 2 2 0px;
  display: flex;
  align-items: center;
  max-width: 300px;
}

.TopBar .TopBarLeftButton {
  justify-content: flex-start;
}

.TopBar .TopBarRightButton {
  justify-content: flex-end;
}

.TopBar .lnr {
  font-size: 1.5em;
}

.TopBar .Button > span {
  display: flex;
  align-items: center;
}

.TopBar .TopBarLeftButton .BackButtonDesktop span {
  padding-right: 4px;
  color: var(--color-text-secondary);
}

.TopBar .TopBarLeftButton .HomeButton span svg,
.TopBar .TopBarLeftButton .BackButtonDesktop span svg {
  margin-right: 12px;
}

ul.TopBarInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.TopBarInfo li {
  padding: 0 5px;
  display: inline-block;
}

ul.TopBarInfo li img {
  width: 34px;
  height: auto;
  margin-top: -2px;
}

.TopBar .logo-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TopBar .logo-header img {
  height: 34px;
  width: 100%;
}
.TopBar .btn-right {
  color: var(--color-text-contrast);
  position: absolute;
  padding-top: 20px;
  top: 0px;
  right: 10px;
}
.TopBar .btn-right .btn-list {
  float: right;
  padding-left: 5px;
}
.TopBar .btn-right button.btn-text {
  font-size: 0.9em;
}

.TopBar .with-icon {
  padding-top: 12px;
}

.TopBar .ScanHistoryButton svg {
  width: 30px;
}

/*--- Tablet and Mobile ---*/
@media (max-width: 992px) {
  .TopBar {
    height: 56px;
    padding: 0 18px;
  }

  .TopBar .btn-right .lnr {
    font-size: 1.7em;
    top: -6px;
  }

  .TopBar .TopBarTitle .TopBarMobileTitle svg {
    width: 20px;
    margin-right: 8px;
    fill: var(--color-text-secondary);
  }
}
