.Register .RecaptchaContainer div {
  width: 100% !important;
  margin-top: 10px;
}

.Register .Checkbox {
  text-align: left;
}

.Register .Checkbox:last-of-type {
  margin-bottom: 22px;
}

.Register .Checkbox label {
  font-weight: normal;
  color: var(--color-text-grey);
  text-align: left;
  align-items: flex-start;
}

.Register .Checkbox label a {
  color: var(--color-text-hyperlink);
  text-decoration: underline;
  font-weight: 600;
}

.Register .Checkbox input {
  margin-bottom: unset;
}

.Register .Checkbox label::before {
  margin-top: 2px;
}

/* Checkmark */
.Register .Checkbox label::after {
  color: transparent;
}

/* Checkmark on the checked state*/
.Register .Checkbox input[type='checkbox']:checked + label::after {
  color: var(--color-text-contrast);
  margin-top: 6px;
}

/* Checkbox on checked state */
.Register .Checkbox input[type='checkbox']:checked + label::before {
  background-color: var(--color-beige);
}

.Register > div {
  max-width: 400px;
  margin: 0 auto;
}
